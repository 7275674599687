import { Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
const NarrativeSummary = ({ summaryData }) => {
    const location = useLocation();
    const pathName = location.pathname;
    return <Grid container >
        {pathName !== "/summary" ? summaryData?.filter((e) => e?.is_deleted !== 1)?.length > 0 && summaryData !== null ? <div className="narrative-section-heading">
            Summary
        </div> : "" : ""}
        {(
            summaryData !== undefined &&
            summaryData?.filter((e) => e?.is_deleted !== 1)?.map((template, templateIndex) => {
                return <>
                    <Grid item xs={12}>
                        {pathName === "/summary" ? <><Typography className="narrative_template_title" sx={{ mt: 2 }} >
                            {template?.templateName ? template?.templateName : ""}
                        </Typography>
                            {template?.summaryDisclaimer?.length > 0 && template?.summaryDisclaimer !== "" && template?.summaryDisclaimer !== "<p><br></p>" ? <span className="narrative-summary-disclaimer">{parse(`<p style="padding: 0px !important;">${template?.summaryDisclaimer?.replace(/[ÂÃ]/g, '')}</p>`)}</span> : ""}
                        </> : ""}
                        {template?.summaryData?.filter((e) => e?.is_deleted !== 1)?.map((summary, summaryIndex) => {
                            return <>
                                <div className="narrative-category-heading">{summary?.name !== null ? summary?.name : ""}</div>
                                {summary?.summary_comment_text?.map((commentText, commentIndex) => {
                                    return <Grid container sx={{ pl: 1, pt: 1 }}>
                                        <Grid item xs={12} key={commentIndex} style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <span style={{ display: 'inline-block', verticalAlign: 'top' }}>{commentIndex + 1}.&nbsp;</span>
                                            <span style={{ display: 'inline-block', verticalAlign: 'top' }}>{parse(`<p style="padding: 0px !important;">${commentText?.replace(/[ÂÃ]/g, '')}</p>`)}</span>
                                        </Grid>
                                    </Grid>
                                })}
                            </>
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={pathName === "/summary" ? "summary-note" : "narrative-summary-note"}>NOTE: This summary is presented to assist in the presentation of information and should never be solely relied upon. The report should be read and understood in its entirety, and the inclusion or omission of certain items in the summary does not indicate any relative importance or special significance. It is important for clients to work closely with their real estate professional in developing any repair requests. Please contact HomeTeam for clarification of any items in this report.</div>
                    </Grid>
                </>
            })
        )}

    </Grid>
}

export default NarrativeSummary;