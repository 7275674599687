import { useState } from "react";
import parse from "html-react-parser";
import { Typography, Grid } from "@mui/material";
import NarrativeSummary from "./NarrativeSummary";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ImageCarousel from "../../../common/Carousel/ImageCarousel";
import "../../../../assets/styles/containers/_narrativeReportContent.scss";

const NarrativeReportContent = ({ categoriesData, summaryData }) => {
    let PhotoCount = 0;
    let VideoCount = 0;
    let mediaFiles = [];
    const [mediaIndex, setMediaIndex] = useState(0);
    const [openImageCarousel, setOpenImageCarousel] = useState(false);
    const handleCloseImageCarousel = () => setOpenImageCarousel(false);
    const handleopenImageCarousel = (url) => {
        setOpenImageCarousel(true);
        setMediaIndex(url);
    }
    const addCaptionForMedia = (type, caption) => {
        if (type?.includes("video")) {
            VideoCount++;
            return <div className="media-caption-text">Video {VideoCount}{caption ? ": " + caption : ""}</div>;
        } else if (type?.includes("image")) {
            PhotoCount++;
            return <div className="media-caption-text">Photo {PhotoCount}{caption ? ": " + caption : ""}</div>;
        }
        return null; // Return null if the type is neither video nor image
    };
    return <Grid container className="narrative-report-content-container">
        {(
            categoriesData !== undefined &&
            categoriesData
                ?.filter((item) => item?.is_narrative_template === 1)
                ?.map((template, templateIndex) => {
                    return <>
                        <Grid item xs={12}>
                            {template?.categories?.filter((e) => e?.lines?.filter((e) => e?.is_deleted !== 1)?.some((line) => line?.comments?.filter((e) => e?.is_deleted !== 1)?.length > 0))?.filter((e) => e?.is_inspection_details === 0 && e?.is_deleted !== 1)?.length > 0 || summaryData || template?.categories?.filter((e) => e?.is_inspection_details !== 1 && e?.is_deleted !== 1)?.some((e) => e?.introductory_text !== null) ? <div key={templateIndex}>
                                <Typography className="narrative_template_title" >
                                    {template.report_title ? template.report_title : template.name}
                                </Typography>
                            </div> : ""}
                        </Grid>
                        <Grid item xs={12} sx={{ p: "30px 20px 20px 20px" }}>
                            <NarrativeSummary summaryData={summaryData?.filter((e) => e?.templateId === template?.template_id)} />
                            {template?.categories?.filter((e) => e?.lines?.filter((e) => e?.is_deleted !== 1)?.some((line) => line?.comments?.filter((e) => e?.is_deleted !== 1)?.length > 0))?.filter((e) => e?.is_inspection_details === 0 && e?.is_deleted !== 1)?.length > 0 || template?.categories?.filter((e) => e?.is_inspection_details !== 1 && e?.is_deleted !== 1)?.some((e) => e?.introductory_text !== null) ? <div className="narrative-section-heading">
                                Report
                            </div> : ""}
                            {template?.categories?.filter((category) =>
                                // Condition 1: Check if category has introductory text
                                category?.introductory_text ||
                                // Condition 2: Check if category lines meet the criteria
                                (
                                    category?.lines?.filter(line => line?.is_deleted !== 1)?.some(line =>
                                        line?.comments?.filter(comment => comment?.is_deleted !== 1)?.length > 0 ||
                                        line?.introductory_text
                                    )
                                )
                            )?.filter(category =>
                                // Exclude categories that are either inspection details or deleted
                                category?.is_inspection_details !== 1 &&
                                category?.is_deleted !== 1
                            )?.map((category, categoryIndex) => {
                                return <Grid item xs={12} id={`${category?.prompt}_${category?.category_id}`} key={categoryIndex}>
                                    <div className="narrative-category-heading">
                                        {categoryIndex + 1}. {category?.prompt !== null ? category?.prompt : ""}
                                    </div>
                                    {category?.introductory_text ? (
                                        <Grid item xs={12} sx={{ p: "10px 0px 10px 0px" }}>
                                            {parse(category?.introductory_text?.replace(/[ÂÃ]/g, ''))}
                                        </Grid>
                                    ) : ""}
                                    {category?.lines?.filter((e) => e?.is_deleted !== 1)?.map((line) => {
                                        return <Grid item xs={12} sx={{ pt: 1 }} >
                                            {line?.comments?.filter((e) => e?.is_deleted !== 1)?.map((comment) => {
                                                return <>
                                                    <Grid item xs={12}>
                                                        {comment?.comment_text && parse(comment?.comment_text?.replace(/[ÂÃ]/g, ''))}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1} sx={{ mt: "15px" }}>
                                                            {comment?.comment_input_controls?.filter((e) => e?.type === 'Media')?.map((commentMedia) => {
                                                                commentMedia?.data?.forEach((value) => (value.type?.includes("image") ? mediaFiles.push({ type: value.type, url: value.onlineUrl, media_id: `${value?.uuid}-${value?.created_at}`, desc: value.description, cat: `${categoryIndex + 1}. ${category?.prompt !== null ? category?.prompt : ""}` }) : mediaFiles.push({ type: value.type, thumb: value.thumbnail, media_id: `${value?.uuid}-${value?.created_at}`, desc: value.description, url: value.onlineUrl, cat: `${categoryIndex + 1}. ${category?.prompt !== null ? category?.prompt : ""}` })));
                                                                return commentMedia?.data?.map((media) => {
                                                                    return <><Grid item xs={6} sx={{
                                                                        position: "relative", mb: 3,
                                                                        cursor: "pointer"
                                                                    }}>
                                                                        <div style={{ display: "block" }}>
                                                                            <img src={media?.type?.includes("image") ? (media.thumbnail ? media.thumbnail : media.onlineUrl) : media.thumbnail} alt="thumbnail" className="narrative-media-container"
                                                                                onClick={() => handleopenImageCarousel(`${media?.uuid}-${media?.created_at}`)}
                                                                            />
                                                                            {media?.type?.includes("video") && <PlayCircleIcon className="narrative-media-play-icon"
                                                                                onClick={() => handleopenImageCarousel(`${media?.uuid}-${media?.created_at}`)}
                                                                            />}
                                                                            {addCaptionForMedia(media?.type, media?.description)}
                                                                        </div>
                                                                    </Grid>
                                                                    </>
                                                                })
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            })}
                                        </Grid>
                                    })}
                                </Grid>
                            })}
                        </Grid>

                    </>
                })
        )}
        <ImageCarousel images={mediaFiles} index={mediaIndex} open={openImageCarousel} handleClose={handleCloseImageCarousel} />
    </Grid>
}

export default NarrativeReportContent;